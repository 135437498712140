import { FC, PropsWithChildren } from 'react';

import clsxm from '../../lib/clsxm';
import { FontWeight, Size } from '../../types/enums';
import { Icon } from '../../types/Icon';
import AhotuLink from './AhotuLink';

interface IAhotwoPillProps {
  isButton?: boolean;
  className?: string;
  size?: Size.sm | Size.md | Size.xs;
  type?: FontWeight.normal | FontWeight.bold;
  url?: string;
  icon?: Icon;
  isFilter?: boolean;
  variant?: 'default' | 'filter' | 'highlight' | 'outlined' | 'disabled';
}

const AhotwoPill: FC<PropsWithChildren<IAhotwoPillProps>> = ({
  children,
  isButton = false,
  url,
  className,
  size = Size.md,
  type = FontWeight.normal,
  icon,
  variant = 'default'
}) => {
  const IconComp = icon;

  const classes = clsxm(
    'group inline-flex text-center rounded-full bg-white',
    {
      'bg-white text-softblack hover:bg-green-70 hover:text-white': variant === 'default',
      'bg-green-5': variant === 'highlight',
      'bg-gray-10 hover:bg-gray-10 hover:text-softblack': variant === 'filter',
      'bg-white border border-gray-30 px-4': variant === 'outlined',
      'text-gray-50 bg-gray-20 pointer-events-none': variant === 'disabled'
    },
    icon && 'justify-center items-center gap-2',
    {
      'px-2 py-1 text-xs': size === Size.xs,
      'px-3 py-1 text-sm': size === Size.sm,
      'px-4 py-2 text-base': size === Size.md,
      'font-semibold': type === FontWeight.bold,
      'font-normal': type === FontWeight.normal
    },
    isButton && 'cursor-pointer',
    className
  );

  const iconClasses = 'flex justify-center items-center h-5 w-5 group-hover:rounded-full group-hover:bg-gray-30';

  if (isButton)
    return (
      <AhotuLink href={url}>
        <div className={classes}>
          {children}
          {IconComp && (
            <div className={iconClasses}>
              <IconComp />
            </div>
          )}
        </div>
      </AhotuLink>
    );
  else {
    return (
      <div className={classes}>
        {children}
        {IconComp && (
          <div className={iconClasses}>
            <IconComp />
          </div>
        )}
      </div>
    );
  }
};

export default AhotwoPill;
